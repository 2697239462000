import React, { useEffect } from "react";
import { useGetOne, Validator } from "react-admin";
import { useWatch, useFormContext } from "react-hook-form";
import AutocompleteInput from "../inputs/AutocompleteInput";
import { SignpostingSymptomService } from "../../types/symptomSignposting";

interface AppointmentFilterInputsProps {
  clientId: string;
  validate?: Validator | Validator[];
}

export const SpecialtyInput = ({
  clientId: id,
  validate
}: AppointmentFilterInputsProps) => {
  const { setValue } = useFormContext();
  const service = useWatch({name: "service"})
  const signpostingService = useWatch({ name: "signpostingService" });
  const { data, isLoading } = useGetOne("availableServices", { id },
    {
      onSuccess: (response) => {
        if (!service && response?.availableServices?.length === 1) {
          setValue("service", response.availableServices[0].id);
        }
      },
    });

  useEffect(() => {
      const serviceId = mapSignpostingServiceToSpecialityId(signpostingService, data?.availableServices);

      if (serviceId) {
        setValue("service", serviceId);
      }

    },
    [signpostingService]);

  const consultationType = useWatch({ name: "consultationType" });

  const handleSelectSpecialty = (
    value: string | number
  ) => {
    if (!data?.appointmentFiltersEnabled) return;

    const selectedService = data?.availableServices?.find((s: any) => s.id === value);
    const isMatch = selectedService?.consultationTypes?.includes(consultationType);
  
    if (!isMatch) {
      setValue("consultationType", "");
    }
  };

  if (isLoading) return null;

  return (
    <AutocompleteInput
      name={"service"}
      sx={{ maxWidth: "248px" }}
      required
      isLoading={isLoading}
      source="service"
      label="Service"
      choices={data?.availableServices ?? []}
      onChange={handleSelectSpecialty}
      validate={validate}
      groupBy={(choice) => choice.group}
    />
  );
};

function mapSignpostingServiceToSpecialityId(signpostingService: SignpostingSymptomService, availableServices: any[]) {
  if (!signpostingService || !availableServices)  {
    return;
  }
  if (signpostingService.attributes.specialty) {
    return availableServices.find(x => x.id === signpostingService.attributes.specialty)?.id;
  }

  if (signpostingService.attributes.clinicalServiceId) {
    return availableServices.find(x => x.id === signpostingService.attributes.clinicalServiceId)?.id;
  }
}

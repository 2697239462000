export type ClinicianType = {
  id: string;
  title: string
  description?: string
}


export const ADVANCED_NURSE_PRACTITIONER = {
  id: 'AdvancedNursePractitioner',
  title: 'Advanced Nurse Practitioner',
  description:
    'Advice and diagnosis for most common minor illnesses and long-term conditions. Please DO NOT choose if you are pregnant',
} as ClinicianType;

export const GENERAL_PRACTITIONER = {
  id: 'GeneralPractitioner',
  title: 'General Practitioner',
  description:
    'Advice and diagnosis for common illnesses and long-term conditions. Recommended if you have multiple, unrelated symptoms',
} as ClinicianType;

export const MENTAL_HEALTH_PRACTITIONER = {
  id: 'Counsellor',
  title: 'Counsellor',
  description: 'Advice and help for managing symptoms of all mental illnesses',
} as ClinicianType;

export const PHYSIOTHERAPIST = {
  id: 'Physiotherapist',
  title: 'Physiotherapist',
  description:
    'Advice, diagnosis, and treatment of muscle, joint, and bone injuries, and aches and pains',
} as ClinicianType;

export const PRESCRIBING_PHARMACIST = {
  id: 'PrescribingPharmacist',
  title: 'Prescribing Pharmacist',
  description:
    'Advice and diagnosis for minor illnesses, help managing conditions such as asthma, and emergency contraception requests',
} as ClinicianType;

export const WOMEN_HEALTH = {
  id: "WomenHealth",
  title: "Women's Health",
  description: 'Advice, diagnosis and treatment of common women’s health issues including but not limited to sexual health, menopause, period concerns, fertility and breast health',
} as ClinicianType;

export const ALL_CLINICIAN_TYPES = [
  ADVANCED_NURSE_PRACTITIONER,
  GENERAL_PRACTITIONER,
  MENTAL_HEALTH_PRACTITIONER,
  PHYSIOTHERAPIST,
  PRESCRIBING_PHARMACIST,
  WOMEN_HEALTH
]


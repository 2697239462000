import { Box, Grid, Typography } from "@mui/material";
import { Colors } from "../../../../theme";
import React from "react";
import { ALL_CLINICIAN_TYPES } from "./clinicianTypes";
import { SignpostingSymptomService } from "../../../../types/symptomSignposting";
import { splitByCapitalLetters } from "../../../../utilities/splitByCapitalLetters";

interface RecommendedServiceProps {
  item: SignpostingSymptomService;
  recommended: boolean;
  selected: boolean;
  onClick: () => void;
}

export function RecommendedService(props: RecommendedServiceProps) {
  const { item, recommended, selected, onClick } = props;
  const { title, description } = getRecommendedServiceData(item);

  return (
    <Grid item xs={12}>
      <Box
        onClick={onClick}
        sx={{
          minHeight: "80px",
          backgroundColor: selected ? Colors.MediumGrey : Colors.White,
          border: selected ? `2px solid ${Colors.BrightBlue}` : "",
          padding: "15px",
          borderRadius: "20px",
          "&:hover": {
            backgroundColor: selected ? Colors.MediumGrey : Colors.LightGrey,
            cursor: "pointer"
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "start"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          {recommended &&
            <Typography color={Colors.Grey} component={"span"}>
              Recommended
            </Typography>
          }
        </Box>
        <Typography variant="subtitle2">
          {description}
        </Typography>
      </Box>
    </Grid>
  );

}

function getRecommendedServiceData(item: SignpostingSymptomService) {
  const defaultClinicianType = ALL_CLINICIAN_TYPES.find(x => x.id === item.attributes.specialty);

  if (defaultClinicianType) {
    return defaultClinicianType;
  }

  if (item.attributes.specialty) {
    return { title: splitByCapitalLetters(item.attributes.specialty), description: "" };
  }

  const defaultClinicalService = ALL_CLINICIAN_TYPES.find(x => x.title.toLowerCase() === item.clinicalServices?.[0].attributes.name.toLowerCase());

  if (defaultClinicalService) {
    return defaultClinicalService
  }

  return {
    title: item.clinicalServices?.[0].attributes.name,
    description: ""
  };
}